'use client'
import React, { useEffect, useState } from 'react'
import Image from 'next/image'

function getRandomNumber(min: number, max: number): number {
  return Math.random() * (max - min) + min
}

interface BeveledImageProps {
  src: string;
  alt: string;
  className?: string;
}

const generatePolygonPoints = (): string => {
  const points = [
    `${getRandomNumber(0, 10)}% ${getRandomNumber(0, 10)}%`,
    `${getRandomNumber(90, 100)}% ${getRandomNumber(0, 10)}%`,
    `${getRandomNumber(90, 100)}% ${getRandomNumber(90, 100)}%`,
    `${getRandomNumber(0, 10)}% ${getRandomNumber(90, 100)}%`,
  ]
  return `polygon(${points.join(', ')})`
}

const BeveledImage: React.FC<BeveledImageProps> = ({ src, alt, className }) => {
  const [clipPath, setClipPath] = useState<string | null>(null)

  useEffect(() => {
    const generateAndSetClipPath = () => setClipPath(generatePolygonPoints())

    generateAndSetClipPath() // Set initially

    const intervalId = setInterval(generateAndSetClipPath, 3000) // Change points every 3 seconds

    return () => clearInterval(intervalId)
  }, [])

  const initialClipPath = 'polygon(4% 6%, 98% 2%, 97% 98%, 2% 96%)' // Initial placeholder

  return (
    <div
      className={`${className || ''}`}
      style={{
        clipPath: clipPath || initialClipPath,
        transition: 'clip-path 3s ease-in-out',
      }}
    >
      <img src={src}
             alt={alt}
             className="h-full w-full object-contain" />
    </div>
  )
}

export default BeveledImage